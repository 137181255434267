<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card>
      <v-card-title> {{ DialogMessage }}</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="editedItem.UomEntry"
              :items="UomEntries"
              item-text="UomName"
              item-value="id"
              label="Unit Of Measure"
              @change="updatePrice"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              type="number"
              label="Quantity"
              outlined
              v-model="editedItem.Amount"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              type="number"
              label="Discount"
              outlined
              @input="updatePrice"
              v-model="editedItem.Discount"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="editedItem.DiscType"
              :items="DiscTypes"
              item-text="text"
              item-value="value"
              label="Discount Type"
              @change="updatePrice"
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="4">
                      <v-text-field
                        readonly
                        dense
                        type="number"
                        label="Special Price"
                        outlined
                        v-model="editedItem.Price"
                      ></v-text-field>
                    </v-col> -->
        </v-row>

        <template v-if="editedItem.DiscType == 2">
          <v-btn color="success" @click="addField" class="my-5">Add</v-btn>

          <v-row dense>
            <v-col
              cols="12"
              v-for="(field, index) in editedItemFields"
              :key="index"
              :class="{
                'd-flex': true,
                'align-items-center': true,
              }"
            >
              <v-autocomplete
                outlined
                dense
                v-model="field.UomEntry"
                :items="UomEntries"
                label="UomEntry"
                item-text="UomName"
                item-value="UomCode"
              ></v-autocomplete>
              <v-autocomplete
                outlined
                dense
                v-model="field.ItemCode"
                label="ItemCode"
                :items="itemsData"
                :item-text="(item) => item.ItemCode + '  -  ' + item.ItemName"
                item-value="ItemCode"
              ></v-autocomplete>
              <v-text-field
                outlined
                dense
                v-model="field.Quantity"
                label="Quantity"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="field.MaxForFree"
                label="MaxForFree"
              ></v-text-field>

              <v-btn class="mx-2" color="warning" @click="removeField(index)"
                >Remove
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="saveChanges"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    editedItemFields: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    DialogMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      priceList: [],
      Items: [],
      OsppItems: [],
      Uoms: [],
      spp1: {},
      spp2: {},
      UomEntries: [],
      itemsData: [],
      DiscTypes: [
        { text: "Discount(%)", value: 1 },
        { text: "Promotional Items", value: 2 },
      ],
      fields: [
        {
          MaxForFree: "",
          UomEntry: "",
          ItemCode: "",
          Quantity: "",
        },
      ],
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    closeDialog() {
      this.$emit("close");
    },
    saveChanges() {
      // console.log(this.editedItemFields);
      this.editedItem.fields = this.editedItemFields;

      this.$emit("save", this.editedItem);
    },
    addField() {
      this.editedItemFields.push({ value: "" });
    },
    removeField(index) {
      this.editedItemFields.splice(index, 1);
    },

    updatePrice() {
      //   this.record.Price = "";
      //   let UomEntry = this.record.UomEntry || 0;
      //   if (UomEntry != 0) {
      //     let uomData = this.Uoms.find(function (item) {
      //       return item.UomEntry == UomEntry;
      //     });
      //     let discount = this.record.Discount || 0;
      //     let discountAmt = uomData.Price * (discount / 100);
      //     this.record.Price = (uomData.Price - discountAmt).toFixed(2);
      //   }
    },
    getUomPrices() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom-prices/${this.$route.params.ItemCode}`)
        .then((res) => {
          self.Uoms = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUoms() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          self.UomEntries = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/items_data`)
        .then((res) => {
          self.itemsData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUomPrices();
    this.getUoms();
    this.getItems();
  },
};
</script>