<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        Volume Discounts
        <v-spacer></v-spacer>
        <v-btn color="success" small @click="editItem(editedItem, 1)">
          Add Item
        </v-btn>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="VolDischeaders"
              :items="spp2Items"
              :search="search"
              small
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.id="{ item }">
                <v-btn @click="editItem(item, 2)" color="light" small>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="removeVolDiscount(item)" color="warning" small>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <edit-dialog
          :editedItem="editedItem"
          :dialog="dialog"
          :DialogMessage="DialogMessage"
          @close="dialog = false"
          @save="SaveVolumeDiscount"
          :editedItemFields="editedItemFields"
        ></edit-dialog>
      </v-card-text>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
import EditDialog from "./_components/editor.vue";

export default {
  components: {
    EditDialog,
  },
  data: () => ({
    search: "",
    priceList: [],
    record: {},
    Items: [],
    OsppItems: [],
    Uoms: [],
    editedItem: {},
    editedItemFields: [],
    spp2: {},
    UomEntries: [],
    itemsData: [],
    dialog: false,
    DialogMessage: "Add Item",
    VdiscountDialog: false,
    PickedPriceList: "",
    SPP1LNum: "",
    action: 1,
    spp2Items: [],
    VolDischeaders: [
      { text: "Uom Code", value: "uom.UomCode" },
      { text: "Uom Name", value: "uom.UomName" },
      { text: "Quantity", value: "Amount" },
      { text: "Discount %", value: "Discount" },
      // { text: "Special Price", value: "Price" },
      { text: "Created On", value: "created_at" },
      { text: "Action", value: "id" },
    ],
  }),
  methods: {
    FetchVolDiscounts() {
      this.SPP1LNum = this.$route.params.SPP1LNum;
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/volume-discounts?SPP1LNum=${this.SPP1LNum}`)
        .then((res) => {
          self.spp2Items = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    SaveVolumeDiscount(data) {
      let item = data;
      if (this.action == 1) {
        this.save(item);
      } else {
        this.update(item);
      }
    },
    save(item) {
      item.SPP1LNum = this.$route.params.SPP1LNum;
      const url = `/volume-discounts`;
      const self = this;
      const data = item;
      console.log(data);
      self.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.dialog = false;
          this.FetchVolDiscounts();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
    update(item) {
      item.SPP1LNum = this.$route.params.SPP1LNum;
      const url = `/volume-discounts/${this.editedItem.id}`;
      const self = this;
      const data = item;
      console.log(data, url);
      self.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.dialog = false;
          this.FetchVolDiscounts();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },

    editItem(item, action) {
      console.log(item);

      if (action == 2) {
        this.action = 2;
        this.DialogMessage = "Edit Item";
        this.editedItem = Object.assign({}, item);
      } else {
        this.action = 1;
        this.DialogMessage = "Add Item";
        this.editedItem = {};
      }
      if (item.fields) {
        this.editedItemFields = item.fields;
      } else {
        this.editedItemFields = [];
      }
      this.dialog = true;
    },
    removeVolDiscount(item) {
      console.log(item.id);
      const url = `/volume-discounts/${item.id}`;
      const self = this;

      self.$store
        .dispatch("remove", url)
        .then(() => {
          this.dialog = false;
          this.spp1 = [];
          this.FetchVolDiscounts();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
    closeDialog() {
      this.editedItem = {};
      this.dialog = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.FetchVolDiscounts();
  },
};
</script>